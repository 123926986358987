class FormMapper {
  static mapToApiData(enhancedForm, additionalOptions) {
    return {
      order: {
        fifth_element: enhancedForm.fifth_element,
        person_name: enhancedForm.name,
        email: enhancedForm.email,
        company_name: enhancedForm.company,
        phone_number: enhancedForm.phone,
        comment: enhancedForm.comment || ' ',
        location_uuid: enhancedForm.locationId,
        option_parking_lots: enhancedForm.option_parking_lots,
        option_send_pdf: additionalOptions.indexOf('option_send_pdf') !== -1,
        option_remember: additionalOptions.indexOf('option_remember') !== -1,
        jeudan_policy: enhancedForm.jeudan_policy,
        ab_code: enhancedForm.ab_code,
        user_token: enhancedForm.user_token,
      },
      api_version: 2,
    };
  }

  static mapToShowCaseParameters(enhancedForm) {
    return {
      order: {
        fifth_element: enhancedForm.fifth_element,
        email: enhancedForm.email,
        person_name: enhancedForm.name,
        company_name: enhancedForm.company,
        phone_number: enhancedForm.phone,
        comment: enhancedForm.comment,
        location_id: enhancedForm.locationId,
        terms_of_service: true,
        newsletter_subscribe: false,
        jeudan_policy: enhancedForm.jeudan_policy,
        ab_code: enhancedForm.ab_code,
        user_token: enhancedForm.user_token,
      },
    };
  }

  static mapToContactWithProviderParameters(enhancedForm) {
    return {
      provider_message: {
        fifth_element: enhancedForm.fifth_element,
        email: enhancedForm.email,
        person_name: enhancedForm.name,
        phone_number: enhancedForm.phone,
        company_name: enhancedForm.company,
        location_id: enhancedForm.locationId,
        newsletter_subscribe: false,
        comment: enhancedForm.comment,
        jeudan_policy: enhancedForm.jeudan_policy,
        ab_code: enhancedForm.ab_code,
        user_token: enhancedForm.user_token,
      },
    };
  }

  static mapToOrderDetailsApiData(formData, additionalOptions) {
    return {
      order_detail: {
        employee_amount: formData.employee_amount,
        monthly_budget_min: formData.monthly_budget_min,
        monthly_budget_max: formData.monthly_budget_max,
        occupied_at_type: formData.occupied_at_type,
        workplace_type: formData.workplace_type,
        occupied_at_date: formData.occupied_at_date,
        currency_id: formData.currency_id,
        order_id: formData.order_id,
      },
      api_version: 2,
    };
  }

  static mapValidationFailParameters(enhancedForm) {
    return {
      validation_fail: {
        visitor_token: enhancedForm.visitor_token,
        form_name: enhancedForm.form_name,
        field_values: enhancedForm.field_values,
        field_errors: enhancedForm.field_errors,
      },
    };
  }
}

export default FormMapper;
